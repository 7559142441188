// Generated by Framer (c163575)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {odEgnYO9A: {hover: true}};

const serializationHash = "framer-H64CR"

const variantClassNames = {odEgnYO9A: "framer-v-1lleuev"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({background, height, hoverBackground, id, link, text, textColor, width, ...props}) => { return {...props, ALqHfl2FI: textColor ?? props.ALqHfl2FI ?? "var(--token-f9e45599-fd40-48ec-aab5-41b4b1e1cd80, rgb(255, 255, 255))", DThcZ2dbv: text ?? props.DThcZ2dbv ?? "Get this Template", EvBVi6gZB: link ?? props.EvBVi6gZB, IrVDgirDQ: background ?? props.IrVDgirDQ ?? "var(--token-e4fdc027-410b-4747-8d19-b7d6252d4400, rgb(28, 28, 28))", jvwT12xlX: hoverBackground ?? props.jvwT12xlX ?? "var(--token-09920af5-e3bc-4f2c-8ef0-faf72c25158d, rgb(51, 51, 51))"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;text?: string;textColor?: string;background?: string;hoverBackground?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, EvBVi6gZB, DThcZ2dbv, ALqHfl2FI, IrVDgirDQ, jvwT12xlX, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "odEgnYO9A", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={EvBVi6gZB} nodeId={"odEgnYO9A"} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1lleuev", className, classNames)} framer-1qzhbga`} data-framer-name={"Primary Button"} layoutDependency={layoutDependency} layoutId={"odEgnYO9A"} ref={ref ?? ref1} style={{backgroundColor: IrVDgirDQ, borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, ...style}} variants={{"odEgnYO9A-hover": {backgroundColor: jvwT12xlX}}} {...addPropertyOverrides({"odEgnYO9A-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItU2VtaUJvbGQ=", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "17px", "--framer-font-weight": "600", "--framer-line-height": "150%", "--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-ALqHfl2FI-fINSTC0Dj))"}}>Get this Template</motion.p></React.Fragment>} className={"framer-15sedcv"} data-framer-name={"Text"} fonts={["Inter-SemiBold"]} layoutDependency={layoutDependency} layoutId={"P5NCCGyTS"} style={{"--extracted-r6o4lv": "var(--variable-reference-ALqHfl2FI-fINSTC0Dj)", "--framer-paragraph-spacing": "0px", "--variable-reference-ALqHfl2FI-fINSTC0Dj": ALqHfl2FI}} text={DThcZ2dbv} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-H64CR.framer-1qzhbga, .framer-H64CR .framer-1qzhbga { display: block; }", ".framer-H64CR.framer-1lleuev { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 16px 24px 16px 24px; position: relative; text-decoration: none; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-H64CR .framer-15sedcv { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-H64CR.framer-1lleuev { gap: 0px; } .framer-H64CR.framer-1lleuev > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-H64CR.framer-1lleuev > :first-child { margin-left: 0px; } .framer-H64CR.framer-1lleuev > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 58
 * @framerIntrinsicWidth 192
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"KqQYQ_e9b":{"layout":["auto","auto"]}}}
 * @framerVariables {"EvBVi6gZB":"link","DThcZ2dbv":"text","ALqHfl2FI":"textColor","IrVDgirDQ":"background","jvwT12xlX":"hoverBackground"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerfINSTC0Dj: React.ComponentType<Props> = withCSS(Component, css, "framer-H64CR") as typeof Component;
export default FramerfINSTC0Dj;

FramerfINSTC0Dj.displayName = "Buttons/ Primary Button";

FramerfINSTC0Dj.defaultProps = {height: 58, width: 192};

addPropertyControls(FramerfINSTC0Dj, {EvBVi6gZB: {title: "Link", type: ControlType.Link}, DThcZ2dbv: {defaultValue: "Get this Template", displayTextArea: false, title: "Text", type: ControlType.String}, ALqHfl2FI: {defaultValue: "var(--token-f9e45599-fd40-48ec-aab5-41b4b1e1cd80, rgb(255, 255, 255))", title: "Text Color", type: ControlType.Color}, IrVDgirDQ: {defaultValue: "var(--token-e4fdc027-410b-4747-8d19-b7d6252d4400, rgb(28, 28, 28)) /* {\"name\":\"Dark Blue 11\"} */", title: "Background", type: ControlType.Color}, jvwT12xlX: {defaultValue: "var(--token-09920af5-e3bc-4f2c-8ef0-faf72c25158d, rgb(51, 51, 51)) /* {\"name\":\"Dark Blue 20\"} */", title: "Hover Background", type: ControlType.Color}} as any)

addFonts(FramerfINSTC0Dj, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/hyOgCu0Xnghbimh0pE8QTvtt2AU.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/NeGmSOXrPBfEFIy5YZeHq17LEDA.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/oYaAX5himiTPYuN8vLWnqBbfD2s.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/lEJLP4R0yuCaMCjSXYHtJw72M.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/cRJyLNuTJR5jbyKzGi33wU9cqIQ.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/1ZFS7N918ojhhd0nQWdj3jz4w.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/A0Wcc7NgXMjUuFdquHDrIZpzZw0.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})